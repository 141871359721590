td {
    border: 1px solid #3d3e78;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
}

th {
    border: 1px solid #3d3e78;
    text-align: left;
    margin: 0;
    padding: 6px 13px;
    font-weight: bold;
}