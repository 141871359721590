.grid-container {
    display: grid;
    grid-template-columns: 190px 1fr;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
        "sidenav header"
        "sidenav main";
    height: 100vh;
}

pre {
    padding: 5px;
    margin: 5px;
}

Button.button-pool {
    margin: 0.5rem;
}

a:link {
    color: lightblue;
}

.string {
    color: rgb(255, 255, 255);
}

.number {
    color: darkorange;
}

.boolean {
    color: rgb(137, 250, 181);
}

.null {
    color: rgb(255, 233, 179);
}

.key {
    color: rgb(193, 166, 255);
}

/* Give every child element its grid name */
.header {
    display: flex;
    grid-area: header;
    background-color: #242424;
    /* justify-content: flex-end; */
}

.sidenav {
    grid-area: sidenav;
    background-color: #1f1f1f;
}

.main {
    margin: 10px;
    grid-area: main;
    background-color: #292929;
}

.footer {
    grid-area: footer;
    background-color: #242424;
}

.sidenavBtn {
    margin-block-end: 10px;
}